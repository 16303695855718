<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event" title="Event Feed"/>

      <div >
        <v-alert tile v-if="!event.published" type="info">This event is not yet published. An event must be published before it can be used or viewed.</v-alert>
        <v-card-text>      
          <p v-if="tenant.isDefault">
            Please read our <a href="https://sodisp.freshdesk.com/support/solutions/folders/77000137491" target="_blank">Frequently asked questions</a> on creating and managing events for tips and tricks.
          </p>
          <p>
            View and manage your event feed. Or post new message to it.
          </p>
          <p>
            The Feed is visible in the app and contains the latest updates on your event. You can automatically post new results and completions to the feed. Each badge can be configured to also post automatically to the feed.
          </p>
          <v-divider class="my-8" />
          <p class="d-flex align-center" style="flex-direction: column;">
            <v-dialog v-model="showPostDialog" max-width="600px">
              <template v-slot:activator="{ on }">
                <v-btn large color="primary" class="" v-on="on"><v-icon small class="mr-2">fa fa-plus</v-icon> Create New Post</v-btn>
              </template>
              <v-card>
                <v-card-title>Post to Event Feed</v-card-title>
                <v-card-text class="mt-4 pb-0">

                  <v-form v-model="valid" lazy-validation ref="postForm">
                    <v-textarea
                      v-model="message"
                      :rules="messageRules"
                      label="Message"
                      />

                    <v-divider />

                    <v-text-field
                      v-model="linkUrl"
                      :rules="urlRules"
                      label="Link to details page (url, optional)"
                      />

                    <v-text-field
                      v-if="!videoUrl"
                      v-model="imageUrl"
                      :rules="urlRules"
                      label="Image (url, optional)"
                      />

                    <v-text-field
                      v-if="!imageUrl"
                      v-model="videoUrl"
                      :rules="videoUrlRules"
                      label="Video (Youtube url, optional)"
                      />

                    <v-select 
                      v-if="!linkUrl"
                      :items="$helpers.prefixSelectListWith(event.races, { name: '- Event feed -', id: null })" 
                      item-text="name" 
                      item-value="id" 
                      v-model="selectedRaceId" 
                      label="Connect with specific leaderboard"
                      />

                    <v-switch 
                      v-model="sendPush" 
                      label="Send Push Notification to the app"
                      />

                    <v-btn large color="primary" @click="postToFeed" :loading="$store.getters.isLoading">Post to Feed</v-btn>
                  </v-form>
                  <br/><br/>
                </v-card-text>
              </v-card>
            </v-dialog>            
          </p>
        </v-card-text>
      </div>      
      <v-card-text>
        <FeedList :feed-items="feedItems" allowDelete @deleteItem="deleteFeedItem" />
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import Header from './_Header.vue'
import FeedList from '@/components/FeedList.vue';
import EventUtil from "@/util/eventUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventFeed",
  components: {
    Header,
    FeedList,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      feedItems: null,
      valid: false,
      message: null,
      linkUrl: null,
      imageUrl: null,
      videoUrl: null,
      sendPush: false,
      selectedRaceId: null,
      showPostDialog: false,
      messageRules: [
        v => !!v || "Please enter the message you want to post",
      ],
      urlRules: [
        v => !v || v.startsWith('https://') || "Please enter a valid url (starting with https://)",
      ],
      videoUrlRules: [
        v => !v|| v.startsWith('https:\/\/www.youtube.com\/watch\?v=') || "Please enter a valid Youtube url (starting with https://www.youtube.com/watch?v=)",
      ],

    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
      await this.loadFeed();
    },

    async loadFeed() {
      this.feedItems = (await eventManagerService.getFeed(this.event.id)).data.data;
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

    async postToFeed() {
      this.$refs.postForm.validate();
      if (this.message && this.valid) {
        const model = {
          msg: this.message,
          img: this.imageUrl,
          video: this.videoUrl,
          link: this.linkUrl,
        };
        const response = (await eventManagerService.postToFeed(this.event.id, model, this.sendPush, this.selectedRaceId)).data;
        this.$helpers.toastResponse(this, response, '👍 Message posted to feed');
        this.showPostDialog = false;
        this.message = null;
        this.sendPush = false;
        await this.loadFeed();

      }
    },

    async deleteFeedItem(feedItemId) {
      if (confirm('Are you sure you want to delete this item?')){
        const response = (await eventManagerService.deleteFeedItem(this.event.id, feedItemId)).data;
        this.$helpers.toastResponse(this, response, 'The item has been deleted successfully.');
        await this.loadFeed();
      }
    },

  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Feed', disabled: true },
      ];
    },
    eventUtil() {
      return new EventUtil(this, this.event, this.race);
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

