<template>
    <div>
      <div v-if="!feedItems" class="pa-8">
        <i><v-icon>fa fa-circle-notch fa-spin</v-icon> Loading feed...</i>
      </div>
      <div v-else-if="feedItems.length == 0">
        <i>Empty feed.</i>
      </div>

      <v-timeline v-else class="mx-4 pt-0" :dense="$vuetify.breakpoint.xsOnly">
          <template v-for="(group, category) in feedItemsByDate">
            <v-timeline-item :key="category" hide-dot class="date-separator">
              <span>{{group.key | localDate("L")}}</span> 
            </v-timeline-item>

            <v-timeline-item
              v-for="(item, idx) in group.data"
              :key="`${category}-${idx}`"
              color="green"
              :small="$vuetify.breakpoint.xsOnly"
              :icon="item.tile ? $helpers.getActivityIcon(item.tile.activity_type) : null"
            >
              <template v-slot:opposite>
                <span>&nbsp;</span>
              </template>
              <v-card v-if="item.type == 'TILE_RANKING'" color="grey lighten-5" class="elevation-1">
                <v-card-title class="subtitle-1 pa-2 pb-0">
                  <strong>#{{ item.tile.pos }}</strong> <span class="grey--text mx-1">(of {{item.tile.cnt}})</span> in
                  <router-link class="ml-1" :to="{name: 'tile', params: { id:item.tile.id, type: item.tile.activity_type.toLowerCase() }, query: { ranking: item.tile.key }}">{{item.tile.name}}</router-link>
                </v-card-title>
                <v-card-text class="pa-2 pb-0">{{ item.msg }}</v-card-text>
                <v-card-actions class="pa-2 pt-0">
                  <v-btn text color="primary" :to="{name: 'tile', params: { id:item.tile.id, type: item.tile.activity_type.toLowerCase()}, query: { ranking: item.tile.key }}">View tile</v-btn>
                  <v-spacer/>
                  <ShareButton :text="`${item.share_msg || item.msg} - Check sodisp.com`" color="primary" :route="{name: 'tile', params: { id:item.tile.id, type: item.tile.activity_type.toLowerCase()}, query: { ranking: item.tile.key }}" />
                </v-card-actions>
              </v-card>
              <v-card v-else-if="item.type == 'EVENT_UPDATE'" color="grey lighten-5" class="elevation-1">
                <v-card-text class="pa-2 pb-0">
                  <vue-markdown class="markdown" :html="false" :source="item.msg" />
                  <img v-if="item.img" :src="item.img" class="feed-image-lg mt-4" />
                  <div v-if="item.video">
                    <p class="text-muted">Inline video player is only available in the app.</p>
                    <v-btn outlined :href="item.video" target="_blank">Watch Video</v-btn>
                  </div>
                </v-card-text>
                <v-card-actions class="pa-2 pt-0">
                    <v-btn v-if="item.link" text color="primary" class="pl-0" :href="item.link" target="_blank" ref="nofollow">{{$t('shared.view')}}</v-btn>
                    <v-btn v-if="item.race && item.race.race_id" text color="primary" class="pl-0" exact :to="{name: 'race', params: { id:item.race.event_id, raceId: item.race.race_id}}">{{$t('profile.feed.view-race')}}</v-btn>
                    <!--<v-btn v-else-if="item.race" text color="primary" class="pl-0" exact :to="{name: 'event', params: { id:item.race.event_id}}">{{$t('shared.view')}}</v-btn>-->
                    <v-spacer/>
                    <v-btn v-if="allowDelete" small icon color="error" @click="deleteFeedItem(item.id)"><v-icon small>fa-trash</v-icon></v-btn>
                </v-card-actions>
              </v-card>
              <v-card v-else-if="item.race && item.race.result_id" color="grey lighten-5" class="elevation-1 d-flex flex-row">
                <div v-if="item.img" class="flex-shrink-1 pa-2">
                  <img :src="item.img" class="feed-image" />
                </div>
                <div class="flex-grow-1 ">
                  <v-card-title class="subtitle-1 pa-2 pb-0">
                    <strong>{{ item.race.event_name }}</strong>
                    <!-- <router-link class="ml-1" :to="{name: 'raceResults', params: { id:item.race.event_id, raceId: item.race.race_id }}">{{item.race.race_name}}</router-link> -->
                  </v-card-title>
                  <v-card-text class="pa-2 pb-0">{{ item.msg }}</v-card-text>
                  <v-card-actions class="pa-0">
                    <v-btn text color="primary" class="pl-0" exact :to="{name: 'raceResult', params: { id:item.race.event_id, raceId: item.race.race_id, resultId: item.race.result_id}}">{{$t('shared.view')}}</v-btn>
                    <v-spacer/>
                    <v-btn v-if="allowDelete" small icon color="error" @click="deleteFeedItem(item.id)"><v-icon small>fa-trash</v-icon></v-btn>
                    <ShareButton icon :text="`${item.share_msg || item.msg} - Check sodisp.com`" color="primary" :route="{name: 'raceResult', params: { id:item.race.event_id, raceId: item.race.race_id, resultId: item.race.result_id}}" />
                  </v-card-actions>
                </div>
              </v-card>
              <v-card v-else-if="item.type == 'EVENT_PHOTO' && item.img" color="grey lighten-5" class="elevation-1 d-flex flex-row">
                <div class="pa-2">
                  <img :src="item.img" style="max-width:100%;" />
                </div>
              </v-card>
              <v-card v-else-if="item.race && item.race.event_id" color="grey lighten-5" class="elevation-1 d-flex flex-row">
                <div v-if="item.img" class="flex-shrink-1 pa-2">
                  <img :src="item.img" class="feed-image" />
                </div>
                <div class="flex-grow-1 ">
                  <v-card-title class="subtitle-1 pa-2 pb-0">
                    <strong>{{ item.race.event_name }}</strong>
                  </v-card-title>
                  <v-card-text class="pa-2 pb-0">{{ item.msg }}</v-card-text>
                  <v-card-actions class="pa-0">
                    <v-btn text color="primary" class="pl-0" exact :to="{name: 'event', params: { id:item.race.event_id}}">{{$t('shared.view')}}</v-btn>
                    <v-spacer/>
                    <v-btn v-if="allowDelete" small icon color="error" @click="deleteFeedItem(item.id)"><v-icon small>fa-trash</v-icon></v-btn>
                    <ShareButton icon :text="`${item.share_msg || item.msg} - Check sodisp.com`" color="primary" :route="{name: 'event', params: { id:item.race.event_id }}" />
                  </v-card-actions>
                </div>
              </v-card>
              <v-card v-else color="grey lighten-5" class="elevation-1">
                <v-card-text class="pa-2">{{ item.msg }}</v-card-text>
                <v-card-actions class="pa-0">
                    <v-spacer/>
                    <v-btn v-if="allowDelete" small icon color="error" @click="deleteFeedItem(item.id)"><v-icon small>fa-trash</v-icon></v-btn>
                </v-card-actions>
              </v-card>
            </v-timeline-item>

          </template>
        </v-timeline>
    </div>    
</template>

<script>
import Vue from "vue";
import { DateTime } from 'luxon';
import ShareButton from '@/components/ShareButton.vue';
import VueMarkdown from '@/components/VueMarkdown.vue'

export default {
  name: "FeedList",
  components: {
    ShareButton,
    VueMarkdown,
  },
  props: {
    feedItems: Array,
    allowDelete: Boolean,
  },
  data: function() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
      deleteFeedItem(id) {
        this.$emit('deleteItem', id);
      },
  },
  computed: {
    feedItemsByDate(){
      var result = this.feedItems.reduce((acc, item) => {
        const date = DateTime.fromISO(item.timestamp).toISODate();
        (acc[date] = acc[date] || []).push(item)
        return acc
      }, {});
      const sorted = [];
      var keys = Object.keys(result);
      keys.sort();; // or loop over the object to get the array
      keys.reverse();
      for (var key of keys) {
        sorted.push({key: key, data: result[key]});
      }
      return sorted;
    },
  },
  watch: {
  }
};
</script>

<style lang="scss">
  .v-timeline {
    .v-timeline-item { margin-top: -15px;  }
    .v-timeline-item:first-child { margin-top: 0; }
    .v-timeline-item.date-separator {
      .v-timeline-item__body { 
        max-width: 100% !important; text-align: center; background: white; font-weight: bold; 
        span { border-top: solid 2px rgba(0, 0, 0, 0.12); border-bottom: solid 2px rgba(0, 0, 0, 0.12); }
      }
      .v-timeline-item__divider { display: none !important; }
    }
    .feed-image { max-height: 100px; max-width: 100px; }
    .feed-image-lg { max-height: 150px; max-width: 100%; }
  }
  .v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {left: 15px;}
  .v-timeline--dense {
    .v-timeline-item { margin-top: 0; }
    .v-timeline-item__divider { min-width: 30px;}
    .v-timeline-item__body { max-width: calc(100% - 30px); }
    .v-timeline-item.date-separator { 
      .v-timeline-item__body { 
        text-align: left;
      }
    }
    .feed-image { max-height: 75px; max-width: 75px; }
  }
</style>